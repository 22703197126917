<template>
  <div>
    <div>
      <van-cell-group>
        <van-field v-model="ActLogsFrom.Title"
          required
          label="标题"
          placeholder="请输入心得体会标题" />
        <van-field v-model="ActLogsFrom.Theme"
          label="主题"
          placeholder="请输入心得体会主题" />
        <van-field v-model="ActLogsFrom.Outline"
          rows="2"
          autosize
          label="摘要"
          type="textarea"
          placeholder="请输入心得体会摘要" />
      </van-cell-group>
      <div style="margin:10px;">
        <van-uploader :after-read="upload"
          :before-delete="fjDelete"
          v-model="fileList"
          accept=""
          :max-count="1">
          <van-button icon="plus"
            plain
            style="border-radius:7px;"
            type="info">上传附件</van-button>
        </van-uploader>
      </div>

    </div>
    <div>
      <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
        <van-button type="info"
          size="normal"
          style="text-center: right; font-size: 16px; width: 100%;border-radius:7px;"
          @click="saveRecActLogs()"
          color="#617bfa">提交</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { WeRecActLogs } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from 'vant';
import axios from "axios";
Vue.use(Toast);
export default {
  data () {
    return {
      list: {}, //活动详情
      ActLogsFrom: {
        AId: '',
        Title: '',
        Theme: '',
        Outline: '',
        Annex: '',
      }, //活动申请提交参数
      fileList: [],
    };
  },
  created () {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
  },
  methods: {
    // 上传附件
    upload: function (file) {
      var that = this;
      console.log(file.file);
      file.status = "uploading";
      file.message = "上传中...";
      var formData = new FormData();
      formData.append('file', file.file)// 文件对象
      let c = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post(config.apiUploadFiles, formData, c)
        .then((res) => {
          if (res.data.code === 0) {
            console.log(res.data.data);
            that.ActLogsFrom.Annex = res.data.data
            file.status = "";
            file.message = "";
          } else {
            Toast.fail(res.data.msg);
          }
        })
    },
    // 删除附件
    fjDelete: function () {
      this.fileList = []
      this.ActLogsFrom.Annex = ""
    },
    saveRecActLogs: function () {
      if (this.ActLogsFrom.Title == "") {
        Toast.fail("请输入心得体会标题");
        return false;
      }
      this.ActLogsFrom.AId = this.$route.params.Id
      WeRecActLogs(this.ActLogsFrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            message: "提交成功!",
          }).then(() => {
            this.$router.go(-1)
          });

        } else {
          Dialog.alert({
            message: "提交失败," + res.data.msg,
          }).then(() => {
          });
        }
      });
    },
  }
}
</script>

<style scoped>
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>